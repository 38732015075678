<div class="container">
  <div class="header">
    <h1>Sinodi per data</h1>
    <div>Dal 1950 al 2050</div>
    <input
      type="date"
      name="date"
      [(ngModel)]="dt"
      placeholder="yyyy-MM-dd"
      min="1950-01-01"
      max="2050-01-01"
      required
      (change)="onDataInputChange()"
      />
  </div>
  <div id="sinodi">
    <table>
      <thead>
        <tr>
          <th>Pianeta</th>
          <th>D1</th>
          <th>R2</th>
          <th>R3</th>
          <th>D4</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let nomeElCeleste of elCelestiOrdine">
          <th>{{nomeElCeleste}}</th>
          <td>{{elCelesti[nomeElCeleste].sinodo[0]}}</td>
          <td>{{elCelesti[nomeElCeleste].sinodo[1]}}</td>
          <td>{{elCelesti[nomeElCeleste].sinodo[2]}}</td>
          <td>{{elCelesti[nomeElCeleste].sinodo[3]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>Timezone {{timezoneGuess}}</div>
  <div>
    N.B.: Gli orari prendono in considerazione anche l'ora legale.
  </div>
  <div class="footer">
    <div>
      <p>
        Se questa pagina ti è stata utile puoi contribuirne allo sviluppo sostenendomi con una donazione su PayPal.<br>
        <a href="https://paypal.me/mirkio?country.x=IT&locale.x=it_IT">Il mio conto PayPal</a>
      </p>
      <p>
        Per qualsiasi informazione, collaborazione, richiesta o segnalazione, puoi scrivermi alla mail <a href="mailto:mirko.lance@gmail.com">mirko.lance@gmail.com</a>.
      </p>
      <div>Mirko Lancerotto</div>
      <div class="socials">
        <a href="http://www.mirkolancerotto.it/" class="fa fa-globe"></a>
        <a href="https://www.facebook.com/mirko.lancerotto/" class="fab fa-facebook"></a>
        <a href="https://www.instagram.com/mirko.lancerotto/" class="fab fa-instagram"></a>
        <a href="https://www.youtube.com/@mirkolancerotto" class="fab fa-youtube"></a>
        <a href="https://www.tiktok.com/@mirkolancerotto" class="fab fa-tiktok"></a>
      </div>
    </div>
  </div>
</div>
